"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.allowTimeoutOverride = exports.rewriteUrl = void 0;

var _core = require("./core");

var _url = require("../helpers/url");

var _update = require("../helpers/update");

const regexRewriteUrl = (url, patternsRaw) => {
  let parsedPatterns = JSON.parse(patternsRaw);

  if (!Array.isArray(parsedPatterns)) {
    // eslint-disable-next-line no-console
    console.error('REWRITE_URL local storage key must be a stringified array');
    return url;
  }

  if (!parsedPatterns.length) return url;
  let urlString = (0, _url.buildUrl)(url);

  if (typeof parsedPatterns[0] === 'string' || parsedPatterns[0] instanceof String) {
    parsedPatterns = [parsedPatterns];
  }

  parsedPatterns.forEach(([pattern, replacement]) => {
    urlString = urlString.replace(new RegExp(pattern), replacement);
  });
  return (0, _url.parseUrl)(urlString);
};

const localOverideUrl = (url, localOverrides) => {
  const parsedOverrides = JSON.parse(localOverrides);

  if (!Array.isArray(parsedOverrides)) {
    // eslint-disable-next-line no-console
    console.error('LOCAL_API_OVERRIDES local storage key must be a stringified array');
    return url;
  }

  let urlString = (0, _url.buildUrl)(url);
  parsedOverrides.forEach(overrideString => {
    if (urlString.includes(overrideString)) {
      urlString = urlString.replace(/https:\/\/(app|api)/, 'https://local');
    }
  });
  return (0, _url.parseUrl)(urlString);
};

const rewriteUrl = options => (0, _core.withUrl)(url => {
  const patternsRaw = options.localStorage && options.localStorage.getItem('URL_REWRITE');

  if (patternsRaw) {
    return regexRewriteUrl(url, patternsRaw);
  }

  const localOverrides = options.localStorage && options.localStorage.getItem('LOCAL_API_OVERRIDES');

  if (localOverrides) {
    return localOverideUrl(url, localOverrides);
  }

  return url;
})(options);

exports.rewriteUrl = rewriteUrl;
const TIMEOUT_OVERRIDE_KEY = 'HUB-HTTP_TIMEOUT';

const allowTimeoutOverride = options => {
  const timeoutOverride = options.localStorage && options.localStorage.getItem(TIMEOUT_OVERRIDE_KEY);

  if (timeoutOverride != null) {
    // eslint-disable-next-line no-console
    console.log(`[hub-http] Using localStorage override ${TIMEOUT_OVERRIDE_KEY} for request timeout.`);
    return (0, _update.set)('timeout', parseInt(timeoutOverride, 10))(options);
  }

  return options;
};

exports.allowTimeoutOverride = allowTimeoutOverride;