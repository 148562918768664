"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.redirectTo = void 0;

var _safeMode = require("./safeMode");

const noop = () => {};

const redirectTo = (redirectUrl, options, onRedirect = noop) => {
  const overrideKey = 'HUB-HTTP_IGNORE_REDIRECTS';

  const isTrue = v => v && v.toLowerCase() === 'true';

  const skipConditions = [[() => options.ignoreRedirect, 'ignoreRedirect option is set'], [() => options.localStorage && isTrue(options.localStorage.getItem(overrideKey)), `local storage key ${overrideKey} is set to "true"`], [() => (0, _safeMode.isSafeMode)(options), 'safe mode is enabled']];
  const condition = skipConditions.find(([predicate]) => predicate());

  if (condition) {
    // eslint-disable-next-line no-console
    console.log(`[hub-http] Skipping redirect because ${condition[1]}`);
    return false;
  }

  onRedirect(options);
  options.location.href = redirectUrl;
  return true;
};

exports.redirectTo = redirectTo;